<template>
    <div class="mechan-page">
        <div class="mechan-img"></div>
        <div class="mechan-text">正在前往第三方机构…</div>
    </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs,reactive } from "vue"
import APP from '../../utils/APP'
import API from '../../server/api'


export default ({
    setup() {
        const state=reactive({
            
            
        })
        onMounted( () => {
            APP.getData('apiDate',function(res){
                const apiURL=JSON.parse(res)
                const {button}=apiURL
                if(button.url){
                    //有第三方h5路径的情况下，页面停留跳转到第三方h5
                    setTimeout(()=>{
                        //通知app此页面的返回至首页 固定的key
                        APP.setData('BackMarkStr',JSON.stringify('goHome'))
                        APP.JUMP_H5(button.url)
                    },500)
                }
            })
          
        })
        return{
            ...toRefs(state),
          
        }
    },
})
</script>